import React from 'react';
import { Link } from "gatsby"
import "../../styles/super-sathi.css"
import { Helmet } from "react-helmet"

class Landing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerShow: false
        };
    }

    render() {
        return (
            <div className='main-container super-sathi-container'>
                <Helmet>
                    <link
                        href="https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700&display=swap"
                        rel="stylesheet"
                    />
                    <link
                        rel="stylesheet"
                        href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        charset="UTF-8"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                    />
                    <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                    />
                    <script src="js/script.js"></script>
                    <script src="https://kit.fontawesome.com/dd15047d18.js" crossorigin="anonymous"></script>
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
                </Helmet>
                <div className="container form-sucess">
                    <img src={require('../../images/super-sathi/sucess.png')} className={"sucess-img"} />
                    <h3>ਧੰਨਵਾਦ !</h3>
                    <p>ਅਸੀਂ ਜਲਦੀ ਹੀ ਤੁਹਾਨੂੰ <br /> ਸੰਪਰਕ ਕਰਾਂਗੇ </p>
                </div>
            </div>
        )
    }
}

export default Landing
